import React from "react"
import { Helmet } from "react-helmet"
import { css } from "@emotion/core"

import Layout from "@components/layout"

export default function Beta() {

  const iframeStyles = css`
    width: 600px;
    height: 800px;
  `
  
  return (
    <main>
      <Helmet>
        <html lang="en" />
        <title>FasTrack: Beta</title>
        <meta name="description" content="FasTrack beta signup" />
      </Helmet>
      <Layout nobg>

        <div className="container py-8">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSenKV4GB7iN-hyoKNa_uXzhAuORkinTW4W_wuPxcfTKO_bofQ/viewform?embedded=true"
          title="GOFOX Ai FasTrack Beta Signup"
          css={iframeStyles}
          className="mx-auto"></iframe>
        </div>

      </Layout>
      
    </main>
  )
}
